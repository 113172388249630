import { TextField, Box } from "@mui/material";
import React, { useState } from "react";
import { TreeNodeElement } from "../selector/tree_node";
function ManualOutflowBox({lineIndex,i,outflow,manualOutflows,setManualOutflows}) {
  const [value, setValue] = useState(manualOutflows?.[lineIndex]?.[i]?.join(", ") || "");
  const handleChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9.,]/g, "");
    setValue(newValue)
    setManualOutflows((prev) => {
      let newManualInflows = [...prev];
      newManualInflows[lineIndex][i] = newValue.replace(" ", "").split(",");
      return newManualInflows;
    });
  }
  return (
    <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              key={`manual-outflow-${lineIndex}-${i}`}
            >
              {outflow === "Manual" && (
                <TextField
                  id="tags-filled"
                  key={`manual-outflow-${lineIndex}-${i}`}
                  label="Manual Outflow"
                  placeholder="กรอกค่าทีละเดือน โดยสามารถกด Enter เพื่อแยกค่าในแต่ละเดือน"
                  helperText={
                    "(ล้าน ลบ.ม./เดือน)"
                  }
                  variant="filled"
                  multiline
                  maxRows={4}
                  type="number" // Use type="text" with comma separation
                  value={value} // Join numbers with comma for display
                  onChange={handleChange}
                ></TextField>
              )}
            </Box>
  )
}
export function ManualOutflowForm({
  selectedOutflow,
  manualOutflows,
  setManualOutflows,
  lineIndex,
  predictionPeriod,
}) {
  let nodeOutflow = new TreeNodeElement(selectedOutflow);
  if (nodeOutflow.value !== undefined) {
    let outflowArray = nodeOutflow.value.toString().split(",");
    return (
      <>
        {outflowArray.map((outflow, i) => {
          return (
            <ManualOutflowBox lineIndex={lineIndex} i={i} outflow={outflow} manualOutflows={manualOutflows} setManualOutflows={setManualOutflows}/>
          );
        })}
      </>
    );
  }
}
