import { TextField } from "@mui/material";
import React, { useState } from "react";
import { ManualInputLengthCount } from "../util/count_manual_input";

export function ManualNampanForm({
  manualNampans,
  setManualNampans,
  idx,
  predictionPeriod,
}) {
  const [value, setValue] = useState(manualNampans?.[idx]?.join(", ") || "");
  return (
    <>
      <TextField
        id="tags-filled"
        label="Manual Diversion Inflow"
        placeholder="กรอกค่าทีละเดือน ตัวอย่าง 1.00,2.00,3.00"
        variant="filled"
        multiline
        error={manualNampans[idx].length > predictionPeriod}
        maxRows={4}
        type="number" // Use type="text" with comma separation
        helperText={
          manualNampans[idx].length > predictionPeriod
            ? "กรุณากรอกค่าไม่เกินจำนวนเดือนที่เลือก"
            : "(ล้าน ลบ.ม./เดือน)" +
              (predictionPeriod - ManualInputLengthCount(manualNampans[idx]) > 0
                ? `, ใส่ได้ ${predictionPeriod} เดือน, คงเหลือ ` +
                  (predictionPeriod -
                    ManualInputLengthCount(manualNampans[idx])) +
                  " เดือน"
                : ", ครบแล้ว")
        }
        value={value} // Join numbers with comma for display
        onChange={(event) => {
          const newValue = event.target.value.replace(/[^0-9.,]/g, "");
          setValue(newValue)
          setManualNampans((prev) => {
            let newManualNampans = [...prev];
            newManualNampans[idx] = newValue.replace(" ", "").split(",");
            return newManualNampans;
          });
          // }
          // setManualNampans(newNumbers.filter((num) => !isNaN(num))); // Filter out non-numbers
        }}
      />
    </>
  );
}
