// import { Sample, Node } from "./components/selector/inflow";
import {
  ActualTimeSelector,
  PredictionTimeSelector,
} from "./components/selector/time";
import { InflowForm } from "./components/inflow_form";
import { DamSelector } from "./components/selector/dam";
import "./App.css";
import React, { useEffect, useMemo, useState } from "react";
import { GetInflowOptions } from "./db/water_data";
// import { getRelativePosition } from "chart.js/helpers";
import InsightsIcon from "@mui/icons-material/Insights";
import SaveIcon from "@mui/icons-material/Save";
import SyncIcon from "@mui/icons-material/Sync";

import {
  FormControl,
  FormLabel,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  createTheme,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import RenderGraph from "./components/graph/graph";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import { ThemeProvider } from "@emotion/react";

import {
  KeyboardArrowRight,
  LogoutOutlined,
  RestartAlt,
} from "@mui/icons-material";
import { axiosStore, globalStore } from "./store";
import Chart from "chart.js/auto";
import { observer } from "mobx-react-lite";

const muiTheme = createTheme({
  typography: {
    fontFamily: "Tahoma",
  },
});

const BodyApp = observer(({ signOutClickHandler }) => {
  // core
  const [selectedDam, setSelectedDam] = useState("NPL");
  const [rawWaterData, setRawWaterData] = useState([
    {
      year: 0,
      month: 0,
      value: 0,
    },
  ]);

  useEffect(() => {
    async function fetchData() {
      const response = await GetInflowOptions(selectedDam);
      setRawWaterData(response.waterCapacity);
    }
    fetchData();
  }, [selectedDam]);
  const currentYear = new Date().getFullYear() + 543;
  const [startYearActual, setStartYearActual] = useState(currentYear - 2);
  const [startMonthActual, setStartMonthActual] = useState(1);
  const [lastRawWaterMonth, setLastRawWaterMonth] = useState(1);
  const [lastRawWaterYear, setLastRawWaterYear] = useState(currentYear);
  useEffect(() => {
    let currentYear = new Date().getFullYear() + 543;
    let currentMonth = new Date().getMonth() + 1;
    let lastRawWaterMonth =
      rawWaterData[rawWaterData.length - 1]?.month || currentMonth;
    let lastRawWaterYear =
      rawWaterData[rawWaterData.length - 1]?.year || currentYear;

    if (lastRawWaterMonth === 12) {
      lastRawWaterMonth = 1;
      lastRawWaterYear += 1;
    } else {
      lastRawWaterMonth += 1;
    }
    setLastRawWaterMonth(lastRawWaterMonth);
    setLastRawWaterYear(lastRawWaterYear);
  }, [rawWaterData]);
  const [startYearPrediction, setStartYearPrediction] =
    useState(lastRawWaterYear);
  const [startMonthPrediction, setStartMonthPrediction] =
    useState(lastRawWaterMonth);
  const [endYearPrediction, setEndYearPrediction] = useState(
    lastRawWaterYear + 1
  );
  const [endMonthPrediction, setEndMonthPrediction] =
    useState(lastRawWaterMonth);
  useEffect(() => {
    setStartYearPrediction(lastRawWaterYear);
    setStartMonthPrediction(lastRawWaterMonth);
    setEndYearPrediction(lastRawWaterYear + 1);
    setEndMonthPrediction(lastRawWaterMonth);
  }, [lastRawWaterMonth, lastRawWaterYear]);
  const [startDatePrediction, setStartDatePrediction] = useState(
    Date.UTC(0, 0, 0)
  );
  const [predictionPeriod, setPredictionPeriod] = useState(12);
  const [inflowCount, setInflowCount] = useState(1);
  // split
  const [selectedInflow, setSelectedInflow] = useState([[], [], []]);
  const [selectedOutflow, setSelectedOutflow] = useState([[], [], []]);
  const [manualOutflows, setManualOutflows] = useState([[], [], []]);
  const [manualNamPanflows, setManualNamPanflows] = useState([[], [], []]);
  const [manualInflows, setManualInflows] = useState([[], [], []]);
  const [startForcastSignal, setStartForcastSignal] = useState();

  const [openModal, setOpenModal] = useState(false)
  const [modalMessage, setModalMessage] = useState("")

  const resetHandler = () => {
    setSelectedInflow(Array(inflowCount).fill([]));
    setSelectedOutflow(Array(inflowCount).fill([]));
    setManualInflows([[], [], []]);
    setManualOutflows([[], [], []]);
    setManualNamPanflows([[], [], []]);
  };
  const forcastHandler = (e) => {
    setStartForcastSignal(e);
  };
  useEffect(() => {
    // end of month
    setStartDatePrediction(
      new Date(startYearPrediction, startMonthPrediction, 0)
    );
  }, [startYearPrediction, startMonthPrediction]);
  useEffect(() => {
    if (endYearPrediction > startYearPrediction) {
      setPredictionPeriod(
        12 * (endYearPrediction - startYearPrediction) +
          endMonthPrediction -
          startMonthPrediction +
          1
      );
    } else if (
      endYearPrediction === startYearPrediction &&
      endMonthPrediction > startMonthPrediction
    ) {
      setPredictionPeriod(endMonthPrediction - startMonthPrediction + 1);
    } else {
      setPredictionPeriod(-1);
    }
  }, [
    endYearPrediction,
    endMonthPrediction,
    startYearPrediction,
    startMonthPrediction,
  ]);
  // Clear data if selectedDam changes
  useMemo(() => {
    setSelectedInflow(Array(inflowCount).fill([]));
    // globalStore.load()
  }, [selectedDam]);

  async function saveSetting() {
    let result = await globalStore.saveSetting({
      selectedDam,
      startYearActual,
      startMonthActual,
      startYearPrediction,
      startMonthPrediction,
      endYearPrediction,
      endMonthPrediction,
      inflowCount,
      selectedInflow,
      manualOutflows,
      manualNamPanflows,
      manualInflows,
    });
    if (!result) {
      setOpenModal(true);
      setModalMessage("Unable to save settings. Please try again later or restart webpage.");
    }
  }

  const loadSetting = async () => {
    let data = await globalStore.loadSetting();
    if (data) {
      setSelectedDam(data.selectedDam);
      setStartYearActual(data.startYearActual);
      setStartMonthActual(data.startMonthActual);
      setStartYearPrediction(data.startYearPrediction);
      setStartMonthPrediction(data.startMonthPrediction);
      setEndYearPrediction(data.endYearPrediction);
      setEndMonthPrediction(data.endMonthPrediction);
      setInflowCount(data.inflowCount);
      setSelectedInflow(data.selectedInflow);
      setManualOutflows(data.manualOutflows);
      setManualNamPanflows(data.manualNamPanflows);
      setManualInflows(data.manualInflows);
    } else {
      setOpenModal(true);
      setModalMessage("No settings found for this user. Please check your configuration or restart webpage.");
    }
  };

  return (
    <Grid className="App" sx={{ height: "70vh", position: "absolute" }}>
      <Dialog
        open={openModal}
        onClose={() => {setOpenModal(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: 'red' }}>
        Error:
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpenModal(false)}} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={muiTheme}>
        <Header />
        <Button
          variant="outlined"
          onClick={signOutClickHandler}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "black",
            height: "33px",
          }}
        >
          Sign Out &nbsp;
          <LogoutOutlined />
        </Button>
        <Grid container sx={{ paddingX: 2, maxHeight: "70vh" }}>
          <Grid
            item
            xs={3}
            sx={{
              height: "80vh",
              flex: "auto",
              overflowY: "auto",
            }}
          >
            <Button
              variant="outlined"
              onClick={saveSetting}
              sx={{ m: 1 }}
              startIcon={<SaveIcon />}
            >
              Save Setting
            </Button>
            <Button
              variant="outlined"
              startIcon={<SyncIcon />}
              onClick={async (e) => {
                await loadSetting();
                forcastHandler(e);
              }}
              sx={{ m: 1 }}
            >
              Load Setting
            </Button>
            <DamSelector
              selectedDam={selectedDam}
              setSelectedDam={setSelectedDam}
            />

            {/* <div className="time-selector"> */}
            <ActualTimeSelector
              year={startYearActual}
              setYear={setStartYearActual}
              month={startMonthActual}
              setMonth={setStartMonthActual}
              selectedDam={selectedDam}
              waterData={rawWaterData}
            />
            {/* </div> */}
            <PredictionTimeSelector
              startYear={startYearPrediction}
              setStartYear={setStartYearPrediction}
              startMonth={startMonthPrediction}
              setStartMonth={setStartMonthPrediction}
              endYear={endYearPrediction}
              setEndYear={setEndYearPrediction}
              endMonth={endMonthPrediction}
              setEndMonth={setEndMonthPrediction}
              lastRawWaterYear={lastRawWaterYear}
              selectedDam={selectedDam}
              waterData={rawWaterData}
            />

            <Grid container>
              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  useFlexGap
                  flexWrap="wrap"
                  justifyContent={"center"}
                >
                  <Button
                    variant="outlined"
                    startIcon={<RestartAlt />}
                    style={{ marginTop: "20px", marginLeft: "10px" }}
                    onClick={resetHandler}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<InsightsIcon />}
                    style={{ marginTop: "20px", marginLeft: "10px" }}
                    onClick={forcastHandler}
                  >
                    Forecast
                  </Button>
                </Stack>

                <FormControl
                  id="select-number-of-lines"
                  sx={{ marginTop: 2, marginBottom: 1 }}
                >
                  <FormLabel
                    id="select-number-of-lines"
                    style={{ fontSize: "16px", color: "black" }}
                  >
                    Number of scenarios
                  </FormLabel>
                  <ToggleButtonGroup
                    color="primary"
                    value={inflowCount}
                    exclusive
                    defaultValue={1}
                    size="small"
                    onChange={(e) => {
                      setInflowCount(Number(e.target.value));
                    }}
                    aria-label="select-number-of-lines"
                    style={{ margin: "auto" }}
                  >
                    <ToggleButton value={1}>1</ToggleButton>
                    <ToggleButton value={2}>2</ToggleButton>
                    <ToggleButton value={3}>3</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </Grid>
            </Grid>
            <InflowForm
              inflowCount={inflowCount}
              predictionPeriod={predictionPeriod}
              selectedInflow={selectedInflow}
              setSelectedInflow={setSelectedInflow}
              selectedOutflow={selectedOutflow}
              setSelectedOutflow={setSelectedOutflow}
              manualOutflows={manualOutflows}
              setManualOutflows={setManualOutflows}
              manualNamPanflows={manualNamPanflows}
              setManualNamPanflows={setManualNamPanflows}
              manualInflows={manualInflows}
              setManualInflows={setManualInflows}
              selectedDam={selectedDam}
            />
          </Grid>

          <Grid item xs={9} paddingX={4}>
            <RenderGraph
              startActualYear={startYearActual}
              startActualMonth={startMonthActual}
              startDatePrediction={startDatePrediction}
              endYearPrediction={endYearPrediction}
              endMonthPrediction={endMonthPrediction}
              predictionPeriod={predictionPeriod}
              selectedInflow={selectedInflow}
              selectedOutflow={selectedOutflow}
              manualOutflows={manualOutflows}
              manualNamPanflows={manualNamPanflows}
              manualInflows={manualInflows}
              selectedDam={selectedDam}
              rawWaterData={rawWaterData}
              startForcastSignal={startForcastSignal}
            />
          </Grid>
          <Footer />
        </Grid>
      </ThemeProvider>
    </Grid>
  );
});

export default BodyApp;
