import { TextField, Box } from "@mui/material";
import React, { useState } from "react";
import { TreeNodeElement } from "../selector/tree_node";

function ManualInflowBox({ lineIndex, i, manualInflows, setManualInflows, inflowOption }) {
  const [value, setValue] = useState(manualInflows?.[lineIndex]?.[i]?.join(", ") || "");
  const handleChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9.,]/g, "");
    setValue(newValue)
    setManualInflows((prev) => {
      let newManualInflows = [...prev];
      newManualInflows[lineIndex][i] = newValue.replace(" ", "").split(",");
      return newManualInflows;
    });
  }
  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "100%" },
      }}
      key={`manual-inflow-${lineIndex}-${i}`}
    >
      {inflowOption === "Manual" && (
        <TextField
          id="tags-filled"
          key={`manual-inflow-${lineIndex}-${i}`}
          label="Manual Inflow"
          placeholder="กรอกค่าทีละเดือน ตัวอย่าง 1.00,2.00,3.00"
          helperText={"(ล้าน ลบ.ม./เดือน)"}
          variant="filled"
          multiline
          maxRows={4}
          type="text" // Use type="text" with comma separation
          value={value} // Join numbers with comma for display
          onChange={handleChange}
        ></TextField>
      )}
    </Box>
  );
}

export function ManualInflowForm({ selectedInflow, manualInflows, setManualInflows, lineIndex, predictionPeriod }) {
  let nodeInflow = new TreeNodeElement(selectedInflow);
  if (nodeInflow.value !== undefined) {
    let inflowArray = nodeInflow.value.toString().split(",");
    return (
      <>
        {inflowArray.map((inflowOption, i) => {
          return (
            <ManualInflowBox
              lineIndex={lineIndex}
              i={i}
              manualInflows={manualInflows}
              setManualInflows={setManualInflows}
              inflowOption={inflowOption}
            />
          );
        })}
      </>
    );
  }
}
