import {
  useMsal,
  useAccount,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalProvider,
} from "@azure/msal-react";
import BodyApp from "./BodyApp";
// Remember that MsalProvider must be rendered somewhere higher up in the component tree
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { loginRequest, msalConfig } from "./constant/auth";
import { useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { axiosStore } from "./store";
import { observer } from "mobx-react-lite";
import { configure } from "mobx";
// import { authStore } from "./store";

function signInClickHandler(instance) {
  // const msalInstance = new PublicClientApplication(msalConfig);
  instance.loginRedirect(loginRequest);
}

// SignInPage Component returns a page with a logo and a button that invokes a popup login when clicked
function SignInPage({ instance }) {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider

  return (
    <div
      className="signin-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      Please supply token to signin /
      <button
        onClick={async () => {
          await instance.initialize()
          signInClickHandler(instance);
        }}
        className="signin-button"
        style={{ marginLeft: "10px" }}
      >
        Sign In with AD
      </button>
    </div>
  );
}

const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
      /*
      * User is not signed in. Throw error or wait for user to login.
      * Do not attempt to log a user in outside of the context of MsalProvider
      */   
  }
  const clientId = process.env.REACT_APP_CLIENT_ID
  const is_dev_clientid = clientId == 'efb70033-a304-4a05-a74c-97e745c0513b'
  const scope_request = is_dev_clientid ? 'WaterRisk_NPRD' : 'WaterRisk_PRD'
  const request = {
      scopes: [`api://${clientId}/${scope_request}`],
      account: activeAccount || accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request)

  return authResult.accessToken
};

configure({
  enforceActions: 'never'
})

const App = observer(() => {
  const { instance, accounts, inProgress } = useMsal();
  const [fetchTokenDone, setFetchTokenStatus] = useState(false)
  const [tokenRefreshInterval, setTokenRefreshInterval] = useState(null);
  const fetchToken = async (account, instance) => {
      try {
      await instance.initialize();
      const tokenResponse = await acquireAccessToken(instance);
      // console.log("tokenResponse",tokenResponse)
      if (tokenResponse) {
        axiosStore.setAccessToken(tokenResponse);
        setFetchTokenStatus(true);
      }
    } catch (error) {
      console.error('Token fetch failed:', error);
      axiosStore.signout();
      localStorage.clear();
    }
  };

  const account = useAccount(accounts[0] || {});

  const signOutClickHandler = () => {
    const logoutRequest = {
      account: account,
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
  }

  useEffect(() => {
    if (account) {
      fetchToken(account, instance);
      // Set up token refresh every 45 minutes (token typically expires in 1 hour)
      const interval = setInterval(() => {
        fetchToken(account, instance);
      }, 1 * 60 * 1000);
      
      setTokenRefreshInterval(interval);
    }

    return () => {
      if (tokenRefreshInterval) {
        clearInterval(tokenRefreshInterval);
      }
    };
  }, [instance, account, inProgress]);
  return (
    <>
      {axiosStore.isAuthenticated && fetchTokenDone ? <BodyApp signOutClickHandler={signOutClickHandler} /> : <SignInPage instance={instance} />}
    </>
  );
})

const msalInstance = new PublicClientApplication(msalConfig);

function Root() {
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <App />
      </Router>
    </MsalProvider>
  );
}

export default Root;
